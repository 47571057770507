import React from 'react';

export default function SpoilerButton(props) {
    const { content } = props;

    const [summary, setSummary] = React.useState(false);
    const [splitContent, setSplitContent] = React.useState(content);
    const [hideContent, setHideContent] = React.useState();

    React.useEffect(() => {
        if (content.length > 189) {
            setSplitContent(content.slice(0, 189));
            setSplitContent(cont => cont.concat("..."));
            setHideContent(content.slice(189));
            setSummary(true);
        }
    }, [content]);


    return (
        <>
            <p className="spoiler-button mt-2">{splitContent}</p>
            {summary ?
                <div>
                    <details>
                        <summary>Continua a leggere</summary>
                        {hideContent}
                    </details>
                </div>
                : null
            }
        </>
    );
}
