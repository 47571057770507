import React from 'react';
import { useCart } from '../utils/contexts/cart-context';
import { useRestaurant } from '../utils/contexts/restaurant-context';
import { IonPage, IonContent, IonIcon } from '@ionic/react';
import { useHistory } from 'react-router';
import formats from '../utils/formats';
import { chevronBackOutline } from 'ionicons/icons';
import SumupWidget from '../components/cart/SumupWidget';
import PaypalWidget from '../components/cart/PaypalWidget';
import StripeWidget from '../components/cart/StripeWidget';

export default function PaymentsPage() {
    let storage = {};

    // const { t } = useTranslation();
    const history = useHistory();

    const { restaurant } = useRestaurant();
    const { number, products, quantity, table, total, type, customers, customer, deliveryCost, order_note } = useCart();

    // const [intent, setIntent] = useState({ "provider": null, "url": null });
    // const [payment, setPayment] = useState("");

    // const onChangeHandler = intent => setIntent(intent);
    // const onLemonwayHandler = () => window.location.href = intent.url;
    const onCloseHandler = () => history.goBack();

    function renderPayment() {
        if (formats.shouldDisplayPaymentsButtonV2(restaurant, "stripe", type)) {
            createCheckout("stripe");
            return (
                <div className="">
                    <StripeWidget order={storage} />
                </div>
            );
        }
        else if (formats.shouldDisplayPaymentsButtonV2(restaurant, "sumup", type)) {
            createCheckout("sumup");
            return (
                <div className="">
                    <SumupWidget order={storage} />
                </div>
            );
        }
        else if (formats.shouldDisplayPaymentsButtonV2(restaurant, "paypal", type)) {
            createCheckout("paypal");
            return (
                <div className="">
                    <PaypalWidget order={storage} />
                </div>
            );
        }
    }

    function createCheckout(payment) {
        let orderTotal = total;

        let coupon = window.sessionStorage.getItem("coupon");
        if (coupon) {
            coupon = JSON.parse(coupon);
            if (String(coupon.restaurantAccepted) === String(restaurant.id)) {
                orderTotal = formats.formatPercentageToPrice(coupon.discount, total);
            }
        }

        if (type === "restaurant") {
            orderTotal = orderTotal + (restaurant.cover_charge * customers);
        } else {
            // orderTotal = orderTotal;

            if (type === "delivery" && deliveryCost.cost_increase !== null && deliveryCost.cost_increase !== undefined) { //TODO: check
                orderTotal = orderTotal + deliveryCost.cost_increase;
            }
        }
        let amount = orderTotal * 100;
        let commissions = (orderTotal * 0.02) * 100;

        storage["restaurant"] = restaurant;
        storage["order"] = {
            "amount": amount,
            "commissions": commissions,
            "customer": customer,
            "customers": customers,
            "number": number,
            "products": products,
            "quantity": quantity,
            "table": table,
            "timestamp": Date.now(),
            "total": orderTotal,
            "type": type,
            "note": order_note,
        };
        storage["transaction"] = {
            "id": "",
            "provider": payment,
        };

        window.localStorage.setItem("__mycontactlessmenu_payload__", JSON.stringify(storage));
    }

    return (
        <IonPage>
            <IonContent style={{ "--background": "#f0edec" }}>
                <section id="payments-page" className="container mx-auto pt-8 font-sans">
                    <div className="flex items-center justify-between px-6">
                        <button type="button" onClick={onCloseHandler} className="back-button flex p-3 rounded-full shadow-lg bg-white">
                            <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
                        </button>
                        <p className="text-xl">Pagamento</p>
                        <span></span>
                    </div>

                    {renderPayment()}
                </section>
            </IonContent>
        </IonPage>
    );
}
