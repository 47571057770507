import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import AutocompleteStreets from './AutocompleteStreets';
import forms from '../../utils/forms';
import { useCart } from '../../utils/contexts/cart-context';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import formats from '../../utils/formats';
import gmapsClient from '../../logic/gmaps';
import TimetablesOrder from './TimetablesOrder';
import OrderTimetablesPopover from './OrderTimetablesPopover';
import ponyuClient from '../../logic/ponyu';
import { DateTime } from "luxon";

export default function PonyUDeliveryForm() {
    let storage = {};
    let submitName;

    const [isDisabled, setIsDisabled] = React.useState(false);
    const [payload, setPayload] = React.useState({ name: "", surname: "", deliveryNote: "", street: "", addressComponents: "", time: "", phone: "" });
    const [address, setAddress] = React.useState("");
    const [coordinates, setCoordinates] = React.useState({
        lat: null,
        lng: null
    });
    const [addressComponents, setAddressComponents] = React.useState("");
    const [times, setTimes] = React.useState([]);
    const [correctTime, setCorrectTime] = React.useState();
    const [message, setMessage] = React.useState(null);
    const [isCorrectRange, setIsCorrectRange] = React.useState(false);
    const [minOrderTime, setMinOrderTime] = React.useState(null);
    const [confirmPonyU, setConfirmPonyU] = React.useState(false);
    const [ponyUTime, setPonyUTime] = React.useState();
    const [orderBlocked, setOrderBlocked] = React.useState(false);

    const { number, products, quantity, setTable, total, payed, setCustomer, setType, deliveryCost, setDeliveryCost, order_note, addOrderNote } = useCart();
    const history = useHistory();
    const { type, id } = useParams();
    const { restaurant } = useRestaurant();
    const { t } = useTranslation();

    function changeHandler(e) {
        let aux = e.target.value;
        if (e.target.name === "name") {
            setPayload(prevState => ({ ...prevState, name: aux }));
        } else if (e.target.name === "surname") {
            setPayload(prevState => ({ ...prevState, surname: aux }));
        } else if (e.target.name === "street") {
            checkCoordinates();
            setPayload(prevState => ({ ...prevState, street: aux, addressComponents: addressComponents }));
        } else if (e.target.name === "delivery_note") {
            setPayload(prevState => ({ ...prevState, deliveryNote: aux }));
        } else if (e.target.name === "time") {
            setPayload(prevState => ({ ...prevState, time: aux, }));
        } else if (e.target.name === "phone") {
            setPayload(prevState => ({ ...prevState, phone: aux }));
        }
    };

    async function fetchPonyU(lat, lng, rangeObject) {
        if (lat && lng && rangeObject) {
            const body = await ponyuClient.doGetWithZone(lat, lng, restaurant.city);

            if (body) {
                let ponyudatetime = DateTime.fromISO(body.value).plus({ minutes: 15 });
                let truePonyuDateTime = false;
                let timeMin = new Date();
                let timeMax = new Date();

                for (let time of times) {
                    timeMin.setHours(time.open[0] + time.open[1], time.open[3] + time.open[4], 0);

                    timeMax.setHours(time.close[0] + time.close[1], time.close[3] + time.close[4], 0);

                    if ((ponyudatetime >= DateTime.fromMillis(timeMin.getTime())) && (ponyudatetime <= DateTime.fromMillis(timeMax.getTime()))) {
                        truePonyuDateTime = true;
                        break;
                    }
                }

                if (truePonyuDateTime) {
                    setPayload(prevState => ({ ...prevState, time: ponyudatetime.toLocaleString(DateTime.TIME_SIMPLE), }));
                    setMinOrderTime(ponyudatetime.toLocaleString(DateTime.TIME_SIMPLE));
                    setPonyUTime(ponyudatetime.toLocaleString(DateTime.TIME_SIMPLE));
                    setCorrectTime(true);
                } else {
                    if (ponyudatetime > DateTime.fromFormat(times[times.length - 1].close, "HH:mm")) {
                        setCorrectTime(false);
                        setOrderBlocked(true);
                    } else {
                        const timeMinParsed = DateTime.fromMillis(timeMin.getTime()).plus({ minutes: 15 }).toLocaleString(DateTime.TIME_SIMPLE);
                        setPayload(prevState => ({ ...prevState, time: timeMinParsed }));
                        setMinOrderTime(timeMinParsed);
                        setPonyUTime(timeMinParsed);
                        setCorrectTime(true);
                    }
                }
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    React.useEffect(() => {
        let today = -1;
        let aux;
        if (restaurant.orders.delivery.active) {
            for (let [index, days] of restaurant.orders.delivery.hours.entries()) {
                aux = formats.getDayOfWeek(days.day);
                if (new Date().getDay() === aux) {
                    today = index;
                    setTimes(
                        (prevState => (
                            [...prevState, { open: restaurant.orders.delivery.hours[index].open, close: restaurant.orders.delivery.hours[index].close }]
                        ))
                    );
                }
            };
        }
        if (!restaurant.orders.delivery.active || today < 0) {
            setIsDisabled(true);
        }
    }, [restaurant.orders.delivery.active, restaurant.orders.delivery.hours]);

    async function checkCoordinates() {
        const rangeObject = restaurant.orders.delivery.distance_fares[0]; //await forms.parseDeliveryRanges(userRange, restaurant.orders.delivery.distance_fares);

        const addressIsInArea = await gmapsClient.checkAddressIsInArea(coordinates, restaurant.pony_u_polygon);

        if (String(addressComponents.zipCode) !== "") {
            if (addressIsInArea) {
                if (await fetchPonyU(coordinates.lat, coordinates.lng, rangeObject)) {
                    setDeliveryCost(rangeObject);
                    setMessage(null);
                    setIsCorrectRange(true);
                } else {
                    setMessage("La tua abitazione è fuori dal raggio di consegna.");
                    setIsCorrectRange(false);
                }

            } else {
                setMessage("La tua abitazione è fuori dal raggio di consegna.");
                setIsCorrectRange(false);
                setPonyUTime("");
                setConfirmPonyU(false);
            }
        } else {
            setMessage("L'indirizzo non è completo.");
            setIsCorrectRange(false);
            setPonyUTime("");
            setConfirmPonyU(false);
        }
    };

    function setSubmit(button) {
        submitName = button.target.id;
    }

    function submitForm(e) {
        e.preventDefault();
        if (!orderBlocked && submitName === "cash" && ((correctTime && isCorrectRange) || (confirmPonyU && isCorrectRange && correctTime))) {
            onClickHandler();
        }
        else if (!orderBlocked && submitName === "cards" && ((correctTime && isCorrectRange) || (confirmPonyU && isCorrectRange && correctTime))) {
            onCheckoutHandler();
        }

        return false;
    }

    const onCheckoutHandler = () => {
        setTable(-1);
        setCustomer(payload);
        setType("delivery");
        history.push(`/${type}/q/${id}/orders/payments`);
    };

    const onClickHandler = () => {
        let finalPrice = total;
        let coupon = window.sessionStorage.getItem("coupon");
        if (coupon) {
            coupon = JSON.parse(coupon);
            if (String(coupon.restaurantAccepted) === String(restaurant.id)) {
                finalPrice = formats.formatPercentageToPrice(coupon.discount, total);
            }
        }

        finalPrice = finalPrice + deliveryCost.cost_increase;

        let amount = finalPrice * 100;
        let commissions = (finalPrice * 0.02) * 100;

        storage["restaurant"] = restaurant;
        storage["order"] = {
            "amount": amount,
            "commissions": commissions,
            "customers": "1",
            "customer": payload,
            "number": number,
            "payed": payed,
            "products": products,
            "quantity": quantity,
            "timestamp": Date.now(),
            "table": "-1",
            "total": finalPrice,
            "type": "delivery",
            "note": order_note,
        };
        storage["transaction"] = {
            "id": forms.generateRandomID("cash_"),
            "provider": "cash",
        };

        window.localStorage.setItem("__mycontactlessmenu_payload__", JSON.stringify(storage));

        if (type === "r") {
            history.push(`/${type}/q/${id}/orders/success/?provider=cash`);
        } else {
            history.push(`/${type}/q/${id}/orders/awaitsms/?provider=cash`);
        }

    };

    function onBlurHandler(e) {
        for (let time of times) {
            let timeMin = new Date();
            let timeMax = new Date();
            let userTime = new Date();

            timeMin.setHours(time.open[0] + time.open[1], time.open[3] + time.open[4], 0);

            timeMax.setHours(time.close[0] + time.close[1], time.close[3] + time.close[4], 0);

            userTime.setHours(e.target.value[0] + e.target.value[1], e.target.value[3] + e.target.value[4], 0);

            if (userTime >= timeMin && userTime <= timeMax) {
                setCorrectTime(true);
                return true;
            }
        }
        setCorrectTime(false);
        return false;
    }

    function confirmPonyUHandler(e) {
        e.preventDefault();
        if (e.target.name === "confirm_ponyu") {
            setPayload(prevState => ({ ...prevState, time: ponyUTime }));
            setConfirmPonyU(true);
        } if (e.target.name === "not_confirm_ponyu") {
            setPayload(prevState => ({ ...prevState, time: "" }));
            setConfirmPonyU(false);
        }
    }

    if (!isDisabled) {
        return (
            <form id="takeaway-form" onSubmit={submitForm}>
                {!orderBlocked ?
                    <>
                        <p className="p-3 text-sm">Scrivi l'indirizzo di consegna del delivery e invia la richiesta al ristoratore. <span className="underline">Ti verrà inviato un SMS con un link per confermare la tua richiesta.</span></p>
                        <div className="pt-3 p-4">
                            <div className="flex">
                                <div className="w-6 ml-3 mr-6">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="fill-current text-mycia-red" viewBox="0 0 690.59 690.9"><g data-name="Livello 2"><path d="M264.52 50.76c-45.26 5.35-65.36 33.43-66.71 75.47-.67 15.53-.71 33.62-.68 49.86.55 9.54-1.38 17.68 1.35 26.95-33.23 23.41-8.22 91.1 22.91 106.47C232.82 337 245.1 369.22 268.56 389v52.56C138.88 458.59-8.42 547.19.38 690.9l690-1.35c1.46-34.17-4.39-67.12-21.57-98.38-47.51-85.87-145.19-133.7-242.58-148.24V385c21.12-17.78 32.46-48.21 43.13-72.78 22.24-13.64 33.69-37.21 36.39-63.34 1.49-14.73-.16-36-12.13-44.47-.65-8.11 1.46-15.34.67-22.91-.92-7.19-2-16.88-1.35-24.93 4.67-51.24 33.95-60.64-6.74-110.51C455.32 11.36 405.73-.33 358.85.89c-14.4-3.05-33.67 2.51-44.47 4.72-22.78 5.47-37.14 20.88-45.82 41.11M305 65.58c10.2-15.64 21.13-19.83 35.62-23.47 4.95-1.24 11.05-2.46 16.15-2.31 11.73.34 18.34.33 31.11 2.87 22 3.93 43.37 8 60.64 22.91 8.46 7.57 16.45 17.38 18.2 29-.43 3.36-2.85 6.28-2.7 10.11-13.19 23.55-12 53.64-10.78 80.86.36 13.35-3.78 38.77-5.39 52.55h18.86c-.32 7.16-1 12-4 17.52-1.42 8.41-6.6 13.53-9.43 20.22l-14.83-1.35c-6.41 24.36-23.57 73.2-45.82 84.9l-8.08 8.09v109.04c-51.34 44.24-25.68 47.41-75.56.11h.01V368.81c-6.48-6.54-14.78-8.72-20.22-17.52-8.33-12.68-16.72-22.86-22.91-38.41-5.16-12.23-9.87-24.37-12.13-37.06l-12.12-1.35c-6.34-4.75-11.27-10.3-13.48-18.87-3.17-5.22-3.22-7.94-5.39-14.82l20.56 2c-4.39-26.94-6.75-54.51-5.74-81.55 1.56-23.72-4.56-52.82 14.15-64.69a109.29 109.29 0 0116.85-5.39 19 19 0 0014.15-2.7M43.5 650.47C61.86 551.44 171.89 501.6 264 483.21c110.46 102.51 53.13 102.71 163.4.27 92.29 15.75 198.41 69.75 219.82 165.64z" data-name="Livello 1" /></g></svg>
                                </div>
                                <p>Chi effettua l'ordine</p>
                            </div>
                            <input type="text" name="name" id="name" className="w-full mt-2 py-3 px-4 rounded-lg bg-grey-200" placeholder="Nome" onChange={changeHandler} required />
                            <input type="text" name="surname" id="surname" className="w-full mt-2 py-3 px-4 rounded-lg bg-grey-200" placeholder="Cognome" onChange={changeHandler} required />
                            <div className="flex pt-8">
                                <div className="w-6 ml-3 mr-6">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="fill-current text-mycia-red" viewBox="0 0 468.42 691.35"><g id="Livello_2" data-name="Livello 2"><g id="Livello_1-2" data-name="Livello 1"><path d="M234.21,0a230.13,230.13,0,0,1,91,18.34A233.89,233.89,0,0,1,450.08,142.5q18.33,42.33,18.34,91.71,0,69.15-33.15,145.33T362.6,519.22q-39.51,63.48-74.07,106.52T249.73,673l-15.52,18.34L218.69,673q-4.23-4.25-38.8-47.27T105.82,519.22Q66.3,455.73,33.16,379.54T0,234.21q0-49.36,18.34-91.71A233.86,233.86,0,0,1,143.21,18.34,229.91,229.91,0,0,1,234.21,0Zm0,625q18.33-22.54,50.79-66.31T348.5,460a911.66,911.66,0,0,0,54.32-114.29q23.28-59.25,23.28-111.46a183.86,183.86,0,0,0-15.52-74.78Q395,124.18,369,98.76a209.25,209.25,0,0,0-60.67-40.91,178.92,178.92,0,0,0-74.07-15.52,178.82,178.82,0,0,0-74.07,15.52A208.72,208.72,0,0,0,99.47,98.76q-26.13,25.39-41.62,60.67a183.53,183.53,0,0,0-15.52,74.78q0,52.21,23.28,111.46A907.47,907.47,0,0,0,119.93,460q31,55,63.49,98.76T234.21,625Zm0-512.16a127.21,127.21,0,0,1,50.09,9.88,123.25,123.25,0,0,1,67,67,127.21,127.21,0,0,1,9.87,50.09,122,122,0,0,1-9.87,49.38,136.55,136.55,0,0,1-26.81,40.21A119.69,119.69,0,0,1,284.3,357a131.91,131.91,0,0,1-100.18,0,119.69,119.69,0,0,1-40.21-27.51,136.33,136.33,0,0,1-26.8-40.21q-9.9-22.56-9.88-49.38a126.69,126.69,0,0,1,9.88-50.09,123.19,123.19,0,0,1,67-67A127.14,127.14,0,0,1,234.21,112.87Zm0,211.64a85,85,0,0,0,32.45-6.35,84.27,84.27,0,0,0,45.86-45.85,84.94,84.94,0,0,0,6.35-32.45A88.76,88.76,0,0,0,312.52,206a76.27,76.27,0,0,0-18.34-26.8,89.2,89.2,0,0,0-27.52-17.64,86.09,86.09,0,0,0-64.9,0,88.57,88.57,0,0,0-27.51,17.64A75.88,75.88,0,0,0,155.91,206a88.57,88.57,0,0,0-6.35,33.87,84.76,84.76,0,0,0,6.35,32.45,84.29,84.29,0,0,0,45.85,45.85A84.91,84.91,0,0,0,234.21,324.51Z" /></g></g></svg>
                                </div>
                                <p>Il tuo indirizzo</p>
                            </div>
                            <AutocompleteStreets address={address} setAddress={setAddress} coordinates={coordinates} setCoordinates={setCoordinates} setAddressComponents={setAddressComponents} focusHandler={changeHandler} />
                            {minOrderTime && deliveryCost && <p className="pt-1 text-center">Il costo aggiuntivo è di {deliveryCost.cost_increase}€</p>}
                            <p className="pt-3 text-center text-red-600">{message}</p>
                            <div>
                                <input className="w-full mt-2 py-3 px-4 rounded-lg bg-grey-200" placeholder="Note dell'ordine" name="notes" value={order_note} onChange={(e) => addOrderNote(e.target.value)} />
                            </div>

                            <div className="text-center py-6">
                                <div className="flex pt-3">
                                    <div className="w-6 ml-3 mr-6">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="fill-current text-mycia-red" viewBox="0 0 690.59 690.9"><g data-name="Livello 2"><path d="M264.52 50.76c-45.26 5.35-65.36 33.43-66.71 75.47-.67 15.53-.71 33.62-.68 49.86.55 9.54-1.38 17.68 1.35 26.95-33.23 23.41-8.22 91.1 22.91 106.47C232.82 337 245.1 369.22 268.56 389v52.56C138.88 458.59-8.42 547.19.38 690.9l690-1.35c1.46-34.17-4.39-67.12-21.57-98.38-47.51-85.87-145.19-133.7-242.58-148.24V385c21.12-17.78 32.46-48.21 43.13-72.78 22.24-13.64 33.69-37.21 36.39-63.34 1.49-14.73-.16-36-12.13-44.47-.65-8.11 1.46-15.34.67-22.91-.92-7.19-2-16.88-1.35-24.93 4.67-51.24 33.95-60.64-6.74-110.51C455.32 11.36 405.73-.33 358.85.89c-14.4-3.05-33.67 2.51-44.47 4.72-22.78 5.47-37.14 20.88-45.82 41.11M305 65.58c10.2-15.64 21.13-19.83 35.62-23.47 4.95-1.24 11.05-2.46 16.15-2.31 11.73.34 18.34.33 31.11 2.87 22 3.93 43.37 8 60.64 22.91 8.46 7.57 16.45 17.38 18.2 29-.43 3.36-2.85 6.28-2.7 10.11-13.19 23.55-12 53.64-10.78 80.86.36 13.35-3.78 38.77-5.39 52.55h18.86c-.32 7.16-1 12-4 17.52-1.42 8.41-6.6 13.53-9.43 20.22l-14.83-1.35c-6.41 24.36-23.57 73.2-45.82 84.9l-8.08 8.09v109.04c-51.34 44.24-25.68 47.41-75.56.11h.01V368.81c-6.48-6.54-14.78-8.72-20.22-17.52-8.33-12.68-16.72-22.86-22.91-38.41-5.16-12.23-9.87-24.37-12.13-37.06l-12.12-1.35c-6.34-4.75-11.27-10.3-13.48-18.87-3.17-5.22-3.22-7.94-5.39-14.82l20.56 2c-4.39-26.94-6.75-54.51-5.74-81.55 1.56-23.72-4.56-52.82 14.15-64.69a109.29 109.29 0 0116.85-5.39 19 19 0 0014.15-2.7M43.5 650.47C61.86 551.44 171.89 501.6 264 483.21c110.46 102.51 53.13 102.71 163.4.27 92.29 15.75 198.41 69.75 219.82 165.64z" data-name="Livello 1" /></g></svg>
                                    </div>
                                    <p>La prima consegna è prevista per: </p>
                                </div>
                                {<div className="flex justify-center items-center pt-2">
                                    <input type="time" value={ponyUTime} name="ponyu" id="ponyu" className="mt-2 mr-6 py-3 px-2 rounded-lg bg-grey-200" disabled />
                                    {!confirmPonyU && ponyUTime &&
                                        <button name="confirm_ponyu" id="confirm_ponyu" className="mt-2 mr-10 py-3 px-2 rounded-lg solid border-2 border-mycia-green" onClick={confirmPonyUHandler} >Conferma consegna</button>
                                    }
                                    {confirmPonyU &&
                                        <button name="not_confirm_ponyu" id="not_confirm_ponyu" className="mt-2 mr-10 py-3 px-2 rounded-lg solid border-2 border-red-400" onClick={confirmPonyUHandler} >Annulla</button>
                                    }
                                </div>}
                                {!confirmPonyU ?
                                    <>
                                        <p className="pt-3">Preferisci cambiare orari di consegna ?</p>
                                        <div className="flex justify-center items-center pt-2">
                                            <input type="time" name="time" id="time" min={minOrderTime} className="mt-2 py-3 px-4 rounded-lg bg-grey-200" placeholder="hh:mm" onBlur={(e) => { return onBlurHandler(e); }} onChange={changeHandler} required />
                                            <OrderTimetablesPopover orders={restaurant.orders.delivery.hours} />
                                        </div>
                                        {!correctTime ? <p className="pt-3 text-red-600">In questo orario il ristorante non effettua consegne, premi il pulsante i per vedere gli orari</p> : null}
                                    </>
                                    : <p className="py-2 text-mycia-green">Hai confermato l'orario</p>}
                            </div>

                            <div className="flex pt-3">
                                <div className="w-6 ml-3 mr-6 text-">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="fill-current text-mycia-red" viewBox="0 0 39.29 39.29"><g data-name="Livello 2"><path d="M39.29 31a9.6 9.6 0 01-.29 2 12.13 12.13 0 01-.59 1.92c-.39.92-1.52 1.91-3.4 3a10.82 10.82 0 01-5.19 1.43 10.69 10.69 0 01-3.09-.45c-.58-.17-1-.3-1.31-.41l-1.55-.57-1.36-.5A24.89 24.89 0 0117.63 35a41.61 41.61 0 01-7.36-6 42.2 42.2 0 01-6-7.37 25.36 25.36 0 01-2.31-4.88l-.54-1.35c-.28-.74-.47-1.26-.57-1.55s-.24-.72-.4-1.31A10.56 10.56 0 010 9.46a10.81 10.81 0 011.42-5.19Q3 1.45 4.38.86A11.12 11.12 0 016.29.28a9 9 0 012-.28 1.67 1.67 0 01.58.08c.34.12.83.82 1.48 2.12.21.36.49.86.84 1.51s.68 1.24 1 1.77.58 1 .86 1.5c.06.07.22.3.49.69a7.68 7.68 0 01.6 1 1.85 1.85 0 01.2.8 2.3 2.3 0 01-.8 1.39 14.1 14.1 0 01-1.73 1.54A16.62 16.62 0 0010 13.87a2.12 2.12 0 00-.79 1.28 1.72 1.72 0 00.14.63c.09.25.17.44.23.57L10 17c.2.32.3.49.32.53a29.74 29.74 0 004.86 6.56A29.17 29.17 0 0021.74 29l.53.32a5.41 5.41 0 00.67.39 4.44 4.44 0 00.57.24 2 2 0 00.63.14 2.09 2.09 0 001.28-.8 16.79 16.79 0 001.48-1.73 13.92 13.92 0 011.53-1.73 2.3 2.3 0 011.4-.79 1.81 1.81 0 01.79.2 8.71 8.71 0 011 .59l.7.49c.46.28 1 .57 1.49.87l1.77 1 1.51.84c1.3.65 2 1.15 2.12 1.48a1.49 1.49 0 01.08.49z" data-name="Livello 1" /></g>
                                    </svg>
                                </div>
                                <p>Il tuo numero di telefono</p>
                            </div>
                            <div className="flex">
                                <input value="+39" className="float-left w-16 mr-6 mt-2 py-3 px-4 rounded-lg bg-grey-200" disabled /><input type="tel" name="phone" id="phone" pattern="^((3[0-9][0-9]))(\d{7})$" className="float-left mt-2 py-3 px-4 rounded-lg bg-grey-200" placeholder="0000000000" onChange={changeHandler} required />
                            </div>
                        </div>

                        <div className="w-full flex flex-col justify-center px-4 py-2 bg-white">
                            <div className="text-xs text-center py-4"> <input type="checkbox" id="terms" name="terms" required />
                                <label className="pl-2">{t("formtable.lable_1.span_1")} <a className="underline" href="https://www.cartaidentitalimentare.com/privacy-esercizi-commerciali/" target="_blank" rel="noreferrer">{t("formtable.lable_1.a_1")}</a> {t("formtable.lable_1.span_2")}</label>
                            </div>
                            {formats.shouldDisplayPaymentsV2(restaurant, "delivery") ? <input type="submit" id="cards" value={t("tablespage.button_1")} className={`block mt-4 py-2 rounded-full bg-mycia-green `} onClick={setSubmit}></input> : null}
                            {formats.shouldDisplayPaymentsButtonV2(restaurant, "cash", "delivery") ? <input type="submit" id="cash" value={t("deliveryform.button_1")} className={`block mt-4 py-2 rounded-full bg-mycia-green `} onClick={setSubmit}></input> : null}
                        </div>
                    </> :
                    <div>
                        <p className="p-4">Il locale non può consegnare il tuo ordine in questa fascia d'orario.</p>
                    </div>}
            </form>
        );
    } else {
        return (
            <React.Fragment>
                <p className="text-center p-10 ">Attenzione, in questo momento il servizio non è disponibile da parte del locale</p>
                <TimetablesOrder orders={restaurant.orders.delivery.hours} />
            </React.Fragment>
        );
    }
}
