import React from "react";
import MorganaRow from "../../list/MorganaRow";
import Tabs from "./Tabs";

export default function TabRouter(props) {
    const { restaurant, hasOrders, defaultTab } = props;

    if (String(restaurant.id) === "9207")
        return restaurant.wrapCourses && restaurant.wrapCourses.length > 0 ?
            restaurant.wrapCourses.map((wrapCourse, index) => <MorganaRow course={wrapCourse} index={index} key={index} />) :
            <p className="text-center">Nessun piatto disponibile</p>;

    switch (process.env.REACT_APP_NAMESPACE) {
        case "mycia": case "aperolspritz": case "campari": case "camparisoda": case "averna": case "wild": case "espolon": case "bulldog": case "braulio": case "crodinoxl": case "redbull": case "sanpellegrino":
            return <Tabs hasOrders={hasOrders} defaultTab={defaultTab}></Tabs>;
        case "morgana":
            return restaurant.wrapCourses && restaurant.wrapCourses.length > 0 ?
                restaurant.wrapCourses.map((wrapCourse, index) => <MorganaRow course={wrapCourse} index={index} key={index} />) :
                <p className="text-center">Nessun piatto disponibile</p>;
        default: //TODO: check
            return <Tabs hasOrders={hasOrders} defaultTab={defaultTab}></Tabs>;
    }
}
