import React from "react";
import * as ReactTabs from "react-tabs";
import { useTranslation } from "react-i18next";
import { useRestaurant } from "../../../utils/contexts/restaurant-context";
import { useCart } from "../../../utils/contexts/cart-context";

import categoriesBuilder from "../../../utils/categoriesBuilder";

import BeveragesContent from "./BeveragesContent";
import Course from "../../menu/Course";
import Preferred from "../../list/Preferred";
import ReviewsTab from "../ReviewsTab";
import Row from "../../list/Row";
import TabsButton from "./TabsButton";
import TastingMenuContent from "./TastingMenuContent";

export default function Tabs(props) {
    const { hasOrders, defaultTab } = props;

    const [activeTab, setActiveTab] = React.useState(defaultTab);
    const [isSearching, setIsSearching] = React.useState(false);
    const [result, setResult] = React.useState([]);
    const { restaurant } = useRestaurant();
    const { t } = useTranslation();
    const { add } = useCart();

    const renderCustomTabName = React.useCallback((idx) => {
        const namespaceConfig = {
            morgana: { tab1: "CUCINA" },
        };

        let customName = restaurant.tabs?.[idx] || t(`tab.button_${idx + 1}`);

        if (namespaceConfig[process.env.REACT_APP_NAMESPACE]?.[`tab${idx + 1}`]) {
            customName = namespaceConfig[process.env.REACT_APP_NAMESPACE][`tab${idx + 1}`];
        }
        return customName;
    }, [restaurant.tabs, t]);

    return (
        <ReactTabs.Tabs defaultIndex={activeTab} onSelect={(index) => setActiveTab(index)}>
            <ReactTabs.TabList>
                <div className="tabs relative">
                    <div className="bar overflow-x-scroll flex mx-3 py-3">
                        <ReactTabs.Tab>
                            <TabsButton tabText={"RECENSIONI"} isActive={activeTab === 0}></TabsButton>
                        </ReactTabs.Tab>
                        {restaurant.tasting_menu.map((tab, index) => (
                            <ReactTabs.Tab key={`tasting-tab-${index}`}>
                                <TabsButton isActive={activeTab === index + 1} tabText={tab.name}></TabsButton>
                            </ReactTabs.Tab>
                        ))}
                        {restaurant.courses.length > 0 && (
                            <ReactTabs.Tab>
                                <TabsButton
                                    isActive={activeTab === restaurant.tasting_menu.length + 1}
                                    tabText={renderCustomTabName(0)}></TabsButton>
                            </ReactTabs.Tab>
                        )}
                        <ReactTabs.Tab>
                            <TabsButton
                                isActive={activeTab === restaurant.tasting_menu.length + 2}
                                tabText={renderCustomTabName(1)}></TabsButton>
                        </ReactTabs.Tab>
                        {restaurant.token !== "IJwpJoOWW" && (
                            <ReactTabs.Tab>
                                <TabsButton
                                    isActive={activeTab === restaurant.tasting_menu.length + 3}
                                    tabText={t("tab.button_3")}>
                                </TabsButton>
                            </ReactTabs.Tab>
                        )}
                    </div>
                </div>
            </ReactTabs.TabList>

            <ReactTabs.TabPanel>
                <ReviewsTab></ReviewsTab>
            </ReactTabs.TabPanel>

            {restaurant.has_tasting_menu ?
                restaurant.tasting_menu.map((menu, index) =>
                    <ReactTabs.TabPanel>
                        <TastingMenuContent
                            menu={menu}
                            hasOrders={hasOrders}
                            add={add}
                            key={index}></TastingMenuContent>
                    </ReactTabs.TabPanel>) : null}

            <ReactTabs.TabPanel>
                {restaurant.courses ?
                    categoriesBuilder.buildCategoriesTree(restaurant.courses).map((course, index) =>
                        hasOrders ?
                            <Row course={course} index={index} key={`course-${index}`}></Row> :
                            <Course course={course} index={index} key={`course-${index}`}></Course>
                    ) : null}
            </ReactTabs.TabPanel>

            <ReactTabs.TabPanel>
                <BeveragesContent
                    beverages={restaurant.beverages}
                    isSearching={isSearching}
                    result={result}
                    hasOrders={hasOrders}
                    setResult={setResult}
                    setIsSearching={setIsSearching}>
                </BeveragesContent>
            </ReactTabs.TabPanel>

            <ReactTabs.TabPanel>
                <Preferred></Preferred>
            </ReactTabs.TabPanel>
        </ReactTabs.Tabs >
    );
}
